import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_METRICAS);

class Metricas_Catalogos {
  obtener_catalogos(options) {
    return http.get(utils.url_options(`/catalogos`,options));
  }

  obtener_catalogo_codigo(codigo) {
    return http.get(`/catalogos/codigo/${codigo}`);
  }

  obtener_catalogo_id(id) {
    return http.get(`/catalogos/${id}`);
  }

  crear_catalogo(payload) {
    return http.post(`/catalogos`,payload);
  }

  editar_catalogo(id, payload) {
    return http.put(`/catalogos/${id}`, payload);
  }

  eliminar_catalogo(id) {
    return http.delete(`/catalogos/${id}`);
  }
}

export default new Metricas_Catalogos();