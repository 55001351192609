<template>
  <div>
    <Modal @close="cerrar_catalogo">
      <form @submit.prevent="agregar_catalogo">
        <div class="title">{{ !editar_catalogo ? 'Agregar catálogo' : 'Editar catálogo' }}</div>
        <div class="body">
          <div class="row form-group">
            <label for="" class="col-forl-label col-sm-2">Nombre</label>
            <div class="col-sm-10"><input v-model="catalogo.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="" class="col-forl-label col-sm-2">Código</label>
            <div class="col-sm-10"><input v-model="catalogo.codigo" type="text" name="codigo" id="codigo" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="" class="col-forl-label col-sm-2">Estatus</label>
            <div class="col-sm-10">
              <select v-model="catalogo.estatus" name="estatus" id="estatus" class="form-control">
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Valores</label>
            <div class="col-sm-10">
              <div class="row mb-3">
                <div class="col-sm-2 offset-sm-10"><button type="button" class="btn secondary-btn" @click="modal_valor=true" :disabled="!($auth.can('metricas','catalogo_crear') || $auth.can('metricas','catalogo_editar'))">Agregar valor</button></div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <table>
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Valor</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(valor,index) in catalogo.valores" :key="index">
                        <td>{{ valor.nombre }}</td>
                        <td>{{ valor.codigo }}</td>
                        <td>
                          <img class="quitar_etapa mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar etapa" title="Editar valor" @click="editar_valor(index)">
                          <img class="quitar_etapa" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar valor" @click="eliminar_valor(index)" title="Elimitar valor">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" :disabled="!($auth.can('metricas','catalogo_crear') || $auth.can('metricas','catalogo_editar'))">Guardar</button></div>
            <div class="col-sm-2"><button type="button" class="btn danger-btn" @click="cerrar_catalogo">Cancelar</button></div>
          </div>
        </div>
      </form>
    </Modal>

    <Modal v-if="modal_valor" :options="{width: '40vw'}" @close="cancelar_valor">
      <form @submit.prevent="agregar_valor">
        <div class="title">Agregar valor</div>
        <div class="body">
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Nombre</label>
            <div class="col-sm-10"><input v-model="valor.nombre" type="text" name="valor.nombre" id="valor.nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Código</label>
            <div class="col-sm-10"><input v-model="valor.codigo" type="text" name="valor.codigo" id="valor.codigo" class="form-control"></div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn secondary-btn mr-2">Guardar</button>
              <button type="button" class="btn danger-btn" @click="cancelar_valor">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from '@/apps/metricas/api/catalogos'

  export default {
    components: {
      Modal
    }
    ,data: function() {
      return {
        catalogo: {
          nombre: null
          ,codigo: null
          ,estatus: 1
          ,valores:[
            // {
            //   nombre: "prueba1"
            //   ,codigo: "prueba"
            // },
            // {
            //   nombre: "prueba2"
            //   ,codigo: "prueba"
            // },
            // {
            //   nombre: "prueba3"
            //   ,codigo: "prueba"
            // },
            // {
            //   nombre: "prueba4"
            //   ,codigo: "prueba"
            // }
          ]
        }
        ,valor: {
          nombre: null
          ,codigo: null
        }
        ,modal_valor: false
      }
    }
    ,props: {
      editar_catalogo: {
        type: Object
      }
    }
    ,mounted: function() {
      if (this.editar_catalogo)
        this.catalogo = this.editar_catalogo;
    }
    ,methods: {
      cerrar_catalogo: function() {
        this.$log.info('cerrar modal');
        this.$emit('close');
      }
      ,cancelar_valor: function() {
        this.valor = {
          nombre: null
          ,codigo: null
        }

        this.modal_valor = false;
      }
      ,agregar_valor: function() {
        if (!this.valor.nombre)
          return this.$helper.showMessage('Error','Debes definir el nombre del valor','error','alert');

        if (!this.valor.codigo)
          return this.$helper.showMessage('Error','Debes definir el código del valor','error','alert');

        if (!this.valor.id)
          this.catalogo.valores.push(this.valor);
        else {
          for(let i=0; i<this.catalogo.valores.length; i++) {
            if (this.valor.id) {
              if (this.valor.id == this.catalogo.valores[i].id) {
                this.catalogo.valores[i] = this.valor;
                i=this.catalogo.length;
              }
            }else {
              if (this.valor.index == index) {
                this.catalogo.valores[i] = this.valor;
                i=this.catalogo.length;
              }
            }
          }
        }

        this.cancelar_valor();
      }
      ,agregar_catalogo: async function() {
        try {
          if (!this.editar_catalogo)
            await api.crear_catalogo(this.catalogo);
          else
            await api.editar_catalogo(this.catalogo.id, this.catalogo);

          this.$emit('update');
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,editar_valor: function(index) {
        if (!this.valor.id)
          this.valor.index = index;

        for(let i=0; i<this.catalogo.valores.length; i++) {
          if (index == i) {
            this.valor = this.catalogo.valores[i];
            i=this.catalogo.length;
          }
        }

        this.modal_valor = true;
      }
      ,eliminar_valor: function(index) {
        let valores = [];

        for(let i=0; i<this.catalogo.valores.length; i++) {
          if (i != index)
            valores.push(this.catalogo.valores[i]);
        }

        this.catalogo.valores = valores;
      }
    }
  }
</script>

<style lang="scss">
  table {
    width: 100%;

    thead {
      tr {
        background-color: #245671 !important;
        th {
          padding: 12px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 5px;

          img {
            width: 15px;
          }
        }

        td:nth-child(3) {
          text-align: center;
        }
      }

      tr:nth-child(2n+0) {
        background-color: #E6E6E6;
      }
    }
  }
</style>